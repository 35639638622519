import EventEmitter from 'o365.modules.EventEmitter.ts';

export default class Sizer {
    constructor(pElement, pOptions) {
        this.element = pElement;
        this.sizer = pElement.firstElementChild;
        this.collapse = pOptions.collapse;
        this.expand = pOptions.expand;
        this.horizontal = pOptions.horizontal ? true : false
        this.id = pOptions.id;
        this.direction = pOptions.direction;
        this.setIconsState = pOptions.setIconsFn
        this.maxWidth = pOptions.maxWidth
        this.maxHeight = pOptions.maxHeight
        this.minWidth = pOptions.minWidth
        this.minHeight = pOptions.minHeight

        this.initiallyCollapsed = pOptions.initiallyCollapsed;
        this.initiallyExpanded = pOptions.initiallyExpanded;

        this.sibling = null;
        this.debounce = null;
        this.eventHandler = new EventEmitter();
        this.elementIsFixed;
        this.siblingIsFixed;
        this.elementIsMin;
        this.siblingIsMin;
        this.initResizeEvents();
        this.listener;
        this.collapseState = null;
        this.ANIMATION_DURATION = 300;
    }

    on(event, listener) {
        return this.eventHandler.on(event, listener);
    }
    off(event, listener) {
        return this.eventHandler.off(event, listener);
    }
    emit = (event, ...args) => {
        return this.eventHandler.emit(event, ...args, this);
    }

    initResizeEvents() {
        //if(this.collapse || this.expand){
        if (this.element.nextElementSibling) {
            if (this.horizontal) {
                this.sizer.style.bottom = "-3px"
            } else {
                this.sizer.style.right = "-3px"
            }
        } else {
            if (this.horizontal) {
                this.sizer.style.top = "-3px"
            } else {
                this.sizer.style.left = "-3px"
            }
        }
        //}

        this.sizer.addEventListener("mousedown", this.onMouseDown);
        this.sizer.addEventListener("touchstart", this.onMouseDown, { passive: true });
        this.sibling = this.element.nextElementSibling ? this.element.nextElementSibling : this.element.previousElementSibling;

        let vLocalStorage = this.#getFromLocalStorage();

        let animationsAdded = false;

        if (vLocalStorage) {
            this.sizer.classList.add(vLocalStorage.collapsed)
            this.collapseState = vLocalStorage.collapsed;
            this.element.style = vLocalStorage.element;
            this.sizer.style = vLocalStorage.sizer;
            this.sibling.style = vLocalStorage.sibling;
            this.elementIsFixed = vLocalStorage.elementIsFixed;
            this.siblingIsFixed = vLocalStorage.siblingIsFixed;
            this.elementIsMin = vLocalStorage.elementIsMin;
            this.siblingIsMin = vLocalStorage.siblingIsMin;
            this.#setIcon()
            this.isLoadedFromLocalStorage = true;
            return;
        } else if (!this.initiallyCollapsed && !this.initiallyExpanded) {
            this.addAnimationClasses();
            animationsAdded = true;
        }

        if (!this.horizontal) {
            if (this.element.style.width.endsWith("px")) {
                this.sibling.style.width = this.sibling.style.width ? this.sibling.style.width : "100%"
            } else {
                this.sibling.style.width = this.sibling.style.width ? this.sibling.style.width : (100 - (+this.element.style.width.replace("%", ""))) + "%";
            }
            if (this.element.style.width.endsWith("px")) {
                this.elementIsFixed = true;
                this.element.style.minWidth = this.element.style.width;
                this.element.style.maxWidth = this.element.style.width;
            } else if (this.sibling.style.width.endsWith("px")) {
                this.siblingIsFixed = true
                this.sibling.style.minWidth = this.sibling.style.width;
                this.sibling.style.maxWidth = this.sibling.style.width;
            } else if (+this.element.style.width.replace("%", "") <= +this.sibling.style.width.replace("%", "")) {

                this.elementIsMin = true
            } else if (+this.element.style.width.replace("%", "") > +this.sibling.style.width.replace("%", "")) {
                this.siblingIsMin = true
            }
        } else {
            if (this.element.style.height.endsWith("px")) {
                this.sibling.style.height = this.sibling.style.height ? this.sibling.style.height : "100%"
            } else {
                this.sibling.style.height = this.sibling.style.height ? this.sibling.style.height : (100 - (+this.element.style.height.replace("%", ""))) + "%";
            }

            if (this.element.style.height.endsWith("px")) {
                this.elementIsFixed = true;
                this.element.style.minHeight = this.element.style.height;
                this.element.style.maxHeight = this.element.style.height;
            } else if (this.sibling.style.height.endsWith("px")) {
                this.siblingIsFixed = true;
                this.sibling.style.minHeight = this.sibling.style.height;
                this.sibling.style.maxHeight = this.sibling.style.height;
            } else if (+this.element.style.height.replace("%", "") <= +this.sibling.style.height.replace("%", "")) {
                this.elementIsMin = true
            } else if (+this.element.style.height.replace("%", "") > +this.sibling.style.height.replace("%", "")) {
                this.siblingIsMin = true
            }
        }

        if (!animationsAdded) {
            if (this.initiallyCollapsed) {
                switch (this.direction) {
                    case 'left':
                        this.left();
                        break;
                    case 'right':
                        this.right();
                        break;
                    case 'bottom':
                        this.bottom();
                        break;
                    case 'top':
                        this.top();
                        break;
                }
            } else if (this.initiallyExpanded) {
                switch (this.direction) {
                    case 'left':
                        this.right();
                        break;
                    case 'right':
                        this.left();
                        break;
                    case 'bottom':
                        this.top();
                        break;
                    case 'top':
                        this.bottom();
                        break;
                }
            }
        }
    }

    #addTransitionEvents() {
        if (!this.listener) {
            this.element.addEventListener('transitionend', this.#transitionEnd.bind(null, this));
            this.sibling.addEventListener('transitionend', this.#transitionEnd.bind(null, this));
        }
        this.listener = true;
    }

    #transitionEnd(that, event) {
        if (event.target.tagName === "I") {
            return
        }
        if (that.element !== event.target) {
            return
        }

        if (that.horizontal === false) {
            if (that.siblingIsFixed && !that.sizer.classList.contains("collapsedRight") && !that.sizer.classList.contains("collapsedLeft")) {
                that.sibling.style.width = that.sibling.clientWidth + "px";
                that.sibling.style.maxWidth = that.sibling.clientWidth + "px";
                that.sibling.style.minWidth = that.sibling.clientWidth + "px";
                that.element.style.width = "100%";
                that.element.style.maxWidth = "";
                that.element.style.minWidth = "";
            }
            if (that.elementIsFixed && !that.sizer.classList.contains("collapsedRight") && !that.sizer.classList.contains("collapsedLeft")) {
                that.element.style.width = that.sizer.style.maxWidth;
                that.element.style.maxWidth = that.sizer.style.maxWidth;
                that.element.style.minWidth = that.sizer.style.maxWidth;

                that.sibling.style.width = "100%";
                that.sibling.style.maxWidth = "";
                that.sibling.style.minWidth = "";
            } else if (that.elementIsMin && !that.sizer.classList.contains("collapsedRight") && !that.sizer.classList.contains("collapsedLeft")) {
                that.sibling.style.maxWidth = "";
                that.sibling.style.minWidth = "";
            }
        } else {

            if (that.siblingIsFixed && !that.sizer.classList.contains("collapsedBottom") && !that.sizer.classList.contains("collapsedTop")) {
                that.sibling.style.height = that.sibling.clientHeight + "px";
                that.sibling.style.maxHeight = that.sibling.clientHeight + "px";
                that.sibling.style.minHeight = that.sibling.clientHeight + "px";
                that.element.style.height = "100%";
                that.element.style.maxHeight = "";
                that.element.style.minHeight = "";
            }
            if (that.elementIsFixed && !that.sizer.classList.contains("collapsedBottom") && !that.sizer.classList.contains("collapsedTop")) {
                that.element.style.height = that.sizer.style.maxHeight;
                that.element.style.maxHeight = that.sizer.style.maxHeight;
                that.element.style.minHeight = that.sizer.style.maxHeight;

                that.sibling.style.height = "100%";
                that.sibling.style.maxHeight = "";
                that.sibling.style.minHeight = "";
            } else if (that.elementIsMin && !that.sizer.classList.contains("collapsedBottom") && !that.sizer.classList.contains("collapsedTop")) {
                that.sibling.style.maxHeight = "";
                that.sibling.style.minHeight = "";
            }
        }
    }

    #fireResize() {
        requestAnimationFrame(() => {
            window.dispatchEvent(new Event('resize'));
        })
    }

    #fireResizeAfterAnimation() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, this.ANIMATION_DURATION + 1);
    }    

    addAnimationClasses = () => {
        this.element.classList.add("margin-sizer-animate");
        this.sibling.classList.add("margin-sizer-animate");
    };

    removeAnimationClasses = () => {
        this.element.classList.remove("margin-sizer-animate");
        this.sibling.classList.remove("margin-sizer-animate");
    };

    #addWidthAnimationClasses = () => {
        this.element.classList.add("width-sizer-animate");
        this.sibling.classList.add("width-sizer-animate");

        setTimeout(() => {
            this.element.classList.remove("width-sizer-animate");
            this.sibling.classList.remove("width-sizer-animate");
        }, this.ANIMATION_DURATION + 1);
    };

    #addHeighthAnimationClasses = () => {
        this.element.classList.add("height-sizer-animate");
        this.sibling.classList.add("height-sizer-animate");

        setTimeout(() => {
            this.element.classList.remove("height-sizer-animate");
            this.sibling.classList.remove("height-sizer-animate");
        }, this.ANIMATION_DURATION + 1);
    };

    onMouseDown = event => {
        this.addAnimationClasses();

        if (this.sizer.classList.contains("collapsedLeft") || this.sizer.classList.contains("collapsedRight") || event.srcElement.tagName === "I") {
            return;
        }
        this.sibling.classList.add("pointer-events");
        this.element.classList.add("pointer-events");
        if (event.srcElement.tagName === "DIV") {
            document.body.addEventListener("mousemove", this.onMove);
            document.body.addEventListener("touchmove", this.onMove);
            document.body.addEventListener("mouseup", this.onMoveEnd);
            document.body.addEventListener("touchend", this.onMoveEnd);
        }
        if (event.type !== 'touchstart') {
            event.preventDefault();
        }
    }

    onMove = event => {
        this.#setSizes(event);
    }

    onMoveEnd = event => {
        this.#setSizes(event, () => {
            if (this.elementIsFixed) {
                if (this.horizontal) {
                    this.element.style.minHeight = this.element.getBoundingClientRect().height + "px";
                    this.element.style.maxHeight = this.element.getBoundingClientRect().height + "px";
                    this.sibling.style.height = "100%";
                } else {
                    this.element.style.minWidth = this.element.getBoundingClientRect().width + "px";
                    this.element.style.maxWidth = this.element.getBoundingClientRect().width + "px";
                    this.sibling.style.width = "100%";
                }

            } else if (this.siblingIsFixed) {
                if (this.horizontal) {
                    this.sibling.style.minHeight = this.sibling.getBoundingClientRect().height + "px";
                    this.sibling.style.maxHeight = this.sibling.getBoundingClientRect().height + "px";
                    this.element.style.height = "100%";
                } else {
                    this.sibling.style.minWidth = this.sibling.getBoundingClientRect().width + "px";
                    this.sibling.style.maxWidth = this.sibling.getBoundingClientRect().width + "px";
                    this.element.style.width = "100%";
                }
            }
            this.#fireResize();
            this.#saveToLocalStorage();
        });
        document.body.removeEventListener("mousemove", this.onMove);
        document.body.removeEventListener("touchmove", this.onMove);
        document.body.removeEventListener("mouseup", this.onMoveEnd);
        document.body.removeEventListener("touchend", this.onMoveEnd);
        this.element.classList.remove("pointer-events");
        this.sibling.classList.remove("pointer-events");

    }

    #saveToLocalStorage() {
        var vCollapsed;
        this.sizer.classList.forEach((item, index) => {
            if (item.startsWith("collapsed")) {
                vCollapsed = item;
            }
        });

        localStorage.setItem(this.id, JSON.stringify({
            element: this.element.getAttribute("style"),
            sibling: this.sibling.getAttribute("style"),
            sizer: this.sizer.getAttribute("style"),
            elementIsFixed: this.elementIsFixed,
            siblingIsFixed: this.siblingIsFixed,
            elementIsMin: this.elementIsMin,
            siblingIsMin: this.siblingIsMin,
            collapsed: vCollapsed
        }));
    }
    #getFromLocalStorage() {
        return JSON.parse(localStorage.getItem(this.id));
    }

    #setSizes(event, callback) {
        if (this.debounce) cancelAnimationFrame(this.debounce);
        this.debounce = window.requestAnimationFrame(_ => {
            if (this.elementIsFixed || this.elementIsMin) {
                this.#setSizesElementPanel(this.horizontal, event);
            } if (this.siblingIsFixed || this.siblingIsMin) {
                this.#setSizesSiblingPanel(this.horizontal, event);
            }
            if (callback && typeof callback == "function") {
                callback()
            }
        });
    }

    #setSizesElementPanel(pHorizontal, event) {
        if (!pHorizontal) {
            let vContainerRects = this.element.parentElement.getBoundingClientRect();
            let vLeft = this.direction === "left" ? this.#getXFromEvent(event) - vContainerRects.x : vContainerRects.width - (this.#getXFromEvent(event) - vContainerRects.left);

            // Check if new size is less than max-width if provided
            if (this.maxWidth != null) {
                const parsedMaxWidth = parseInt(this.maxWidth);
                if (this.maxWidth.includes('%')) {
                    if (((vLeft / vContainerRects.width) * 100) > parsedMaxWidth) { vLeft = (parsedMaxWidth/100) * vContainerRects.width; }
                } else {
                    if (vLeft > parsedMaxWidth) { vLeft = parsedMaxWidth; }
                }
            }

            // Check if new size is greater than min-width if provided
            if (this.minWidth != null) {
                const parsedminWidth = parseInt(this.minWidth);
                if (this.minWidth.includes('%')) {
                    if (((vLeft / vContainerRects.width) * 100) < parsedminWidth) { vLeft = (parsedminWidth/100) * vContainerRects.width; }
                } else {
                    if (vLeft < parsedminWidth) { vLeft = parsedminWidth; }
                }
            }

            this.sibling.style.width = (100 - ((vLeft / vContainerRects.width) * 100)) + '%'
            this.element.style.minWidth = "";
            this.element.style.maxWidth = "";

            if (this.elementIsMin) {
                this.element.style.width = ((vLeft / vContainerRects.width) * 100) + '%'
            } else {
                this.element.style.width = vLeft + "px";
            }
        } else {
            let vContainerRects = this.element.parentElement.getBoundingClientRect();
            let vTop = this.direction === "top" ? this.#getYFromEvent(event) - vContainerRects.y : vContainerRects.height - (this.#getYFromEvent(event) - vContainerRects.top);

            // Check if new size is less than max-height if provided
            if (this.maxHeight != null) {
                const parsedMaxHeight = parseInt(this.maxHeight);
                if (this.maxHeight.includes('%')) {
                    if (((vTop / vContainerRects.height) * 100) > parsedMaxHeight) { vTop = (parsedMaxHeight / 100) * vContainerRects.height; }
                } else {
                    if (vTop > parsedMaxHeight) { vTop = parsedMaxHeight; }
                }
            }

            // Check if new size is greater than min-height if provided
            if (this.minHeight != null) {
                const parsedminHeight = parseInt(this.minHeight);
                if (this.minHeight.includes('%')) {
                    if (((vTop / vContainerRects.height) * 100) < parsedminHeight) { vTop = (parsedminHeight / 100) * vContainerRects.height; }
                } else {
                    if (vTop < parsedminHeight) { vTop = parsedminHeight; }
                }
            }


            this.sibling.style.height = (100 - ((vTop / vContainerRects.height) * 100)) + '%'
            this.element.style.minHeight = "";
            this.element.style.maxHeight = "";
            if (this.elementIsMin) {
                this.element.style.height = ((vTop / vContainerRects.height) * 100) + '%'
            } else {
                this.element.style.height = vTop + "px";
                this.element.style.minHeight = vTop + "px";
                this.element.style.maxHeight = vTop + "px";
            }
        }
    }

    #setSizesSiblingPanel(pHorizontal, event) {
        if (!pHorizontal) {
            let vContainerRects = this.element.parentElement.getBoundingClientRect();
            //let vRight = (event.x - vContainerRects.x - vContainerRects.width) * -1;
            let vRight = this.direction === "right" ? this.#getXFromEvent(event) - vContainerRects.x : vContainerRects.width - (this.#getXFromEvent(event) - vContainerRects.left);

            // Check if new size is less than max-width if provided
            if (this.maxWidth != null) {
                const parsedMaxWidth = parseInt(this.maxWidth);
                if (this.maxWidth.includes('%')) {
                    if ((((vContainerRects.width - vRight) / vContainerRects.width) * 100) > parsedMaxWidth) { vRight = ((100-parsedMaxWidth)/100) * vContainerRects.width; }
                } else {
                    if (vContainerRects.width - vRight > parsedMaxWidth) { vRight = vContainerRects.width - parsedMaxWidth; }
                }
            }

            if (this.siblingIsMin) {
                this.sibling.style.width = (((vRight / vContainerRects.width) * 100)) + '%'
            } else {
                this.sibling.style.width = vRight + "px";
            }
            this.element.style.width = (100 - ((vRight / vContainerRects.width) * 100)) + '%';
            this.sibling.style.minWidth = "";
            this.sibling.style.maxWidth = "";
        } else {

            let vContainerRects = this.element.parentElement.getBoundingClientRect();
            let vBottom = this.direction === "bottom" ? this.#getYFromEvent(event) - vContainerRects.y : vContainerRects.height - (this.#getYFromEvent(event) - vContainerRects.top);

            // Check if new size is less than max-height if provided
            if (this.maxHeight != null) {
                const parsedMaxHeight = parseInt(this.maxHeight);
                if (this.maxHeight.includes('%')) {
                    if ((((vContainerRects.height - vBottom) / vContainerRects.height) * 100) > parsedMaxHeight) { vBottom = ((100 - parsedMaxHeight) / 100) * vContainerRects.height; }
                } else {
                    if (vContainerRects.height - vBottom > parsedMaxHeight) { vBottom = vContainerRects.height - parsedMaxHeight; }
                }
            }

            //let vBottom = (event.y - vContainerRects.y - vContainerRects.height) * -1;
            if (this.siblingIsMin) {
                //this.sizer.style.bottom = ((vContainerRects.height-this.element.clientHeight)/vContainerRects.height) * 100 + "%";
                this.sibling.style.height = (((vBottom / vContainerRects.height) * 100)) + '%'
            } else {
                this.sizer.style.bottom = this.sibling.clientHeight + "px";
            }
            this.element.style.height = (100 - ((vBottom / vContainerRects.height) * 100)) + '%';
            this.sibling.style.minHeight = "";
            this.sibling.style.maxHeight = "";
        }
    }

    left() {
        this.#addTransitionEvents();
        let vContainerRects = this.element.parentElement.getBoundingClientRect();
        if (this.sizer.classList.contains("collapsedLeft")) {
            if (this.expand === false) {
                this.right();
                this.#setIcon();
            }
            return;
        }

        if (this.sizer.classList.contains("collapsedRight")) {
            this.sizer.classList.remove("collapsedRight");
            this.collapseState = null;
            if (this.elementIsFixed) {
                if (this.direction === "left") {
                    this.element.style.width = (vContainerRects.width - this.sibling.style.maxWidth.replace("px", "")) + "px";
                    this.element.style.maxWidth = (vContainerRects.width - this.sibling.style.maxWidth.replace("px", "")) + "px";
                    this.element.style.minWidth = (vContainerRects.width - this.sibling.style.maxWidth.replace("px", "")) + "px";
                    this.sibling.style.width = "100%"
                    this.sibling.style.maxWidth = "";
                    this.sibling.style.minWidth = "";
                    // this.sibling.style.width = ((vContainerRects.width - (+this.sizer.style.maxWidth.replace("px", "")))) + "px";
                    // this.sibling.style.maxWidth = (vContainerRects.width - (+this.sizer.style.maxWidth.replace("px", ""))) + "px";
                    // this.sibling.style.minWidth = (vContainerRects.width - (+this.sizer.style.maxWidth.replace("px", ""))) + "px";
                    // this.sibling.style.marginRight = (vContainerRects.width - (+this.sizer.style.maxWidth.replace("px", ""))) * -1 + "px";
                } else {
                    this.element.style.marginRight = 0;
                }
            } else if (this.elementIsMin || this.siblingIsMin) {
                this.#addWidthAnimationClasses();
                if (this.direction === 'right') {
                    this.element.style.marginRight = '';
                }
                this.sibling.style.width = this.sizer.style.maxWidth;
                this.element.style.width = (100 - (+this.sizer.style.maxWidth.replace("%", ""))) + "%";
            } else if (this.siblingIsMin) {

            }

            this.sibling.style.marginRight = "";
            this.#setIcon();
        } else {
            if (this.siblingIsFixed) {
                const elClientWidth = this.element.clientWidth;
                this.element.style.width = elClientWidth + "px";
                this.element.style.maxWidth = elClientWidth + "px";
                this.element.style.minWidth = elClientWidth + "px";
                this.sibling.style.width = "100%";
                this.sibling.style.maxWidth = "";
                this.sibling.style.minWidth = "";
                this.element.style.marginLeft = elClientWidth * -1 + "px"
                this.sizer.style.maxWidth = elClientWidth + "px";
            } else if (this.elementIsFixed) {
                if (this.direction === "left") {
                    const elClientWidth = this.element.clientWidth;
                    this.element.style.marginLeft = elClientWidth * -1 + "px"
                    this.sizer.style.maxWidth = elClientWidth + "px";
                } else {
                    const sbClientWidth = this.sibling.clientWidth;
                    const elClientWidth = this.element.clientWidth;
                    this.sibling.style.width = sbClientWidth + "px";
                    this.sibling.style.maxWidth = sbClientWidth + "px";
                    this.sibling.style.minWidth = sbClientWidth + "px";
                    this.sibling.style.marginLeft = sbClientWidth * -1 + "px";
                    this.element.style.width = "100%";
                    this.element.style.minWidth = "";
                    this.element.style.maxWidth = "";
                    this.sizer.style.maxWidth = elClientWidth + "px";
                }

            } else if (this.elementIsMin || this.siblingIsMin) {
                this.#addWidthAnimationClasses();
                this.sizer.style.maxWidth = this.element.style.width;
                if (this.direction === 'left') {
                    const elClientWidth = this.element.clientWidth;
                    this.element.style.marginLeft = elClientWidth * -1 + "px"
                    this.element.style.width = elClientWidth + "px";
                    this.sibling.style.width = "100%";
                } else {
                    const sbClientWidth = this.sibling.clientWidth;
                    this.sibling.style.marginLeft = sbClientWidth * -1 + "px"
                    this.sibling.style.width = sbClientWidth + "px";
                    this.element.style.width = "100%";
                }
            }


            this.sizer.classList.add("collapsedLeft");
            this.collapseState = 'collapsedLeft';
            this.#setIcon();
        }
        this.#fireResizeAfterAnimation();
        this.#saveToLocalStorage();
    }

    right() {        
        this.#addTransitionEvents();
        let vContainerRects = this.element.parentElement.getBoundingClientRect();
        if (this.sizer.classList.contains("collapsedRight")) {
            if (this.collapse === -1) {
                this.left();
                this.#setIcon();
            }
            return;
        }

        if (this.sizer.classList.contains("collapsedLeft")) {            
            this.sizer.classList.remove("collapsedLeft");
            this.collapseState = null;
            if (this.siblingIsFixed) {
                this.element.style.width = ((vContainerRects.width - (+this.sizer.style.maxWidth.replace("px", "")))) + "px";
                this.element.style.maxWidth = (vContainerRects.width - (+this.sizer.style.maxWidth.replace("px", ""))) + "px";
                this.element.style.minWidth = (vContainerRects.width - (+this.sizer.style.maxWidth.replace("px", ""))) + "px";
                this.element.style.marginLeft = (vContainerRects.width - (+this.sizer.style.maxWidth.replace("px", ""))) * -1 + "px";
            } else if (this.elementIsFixed) {
                if (this.direction === "right") {
                    this.element.style.width = (vContainerRects.width - this.sibling.style.maxWidth.replace("px", "")) + "px";
                    this.element.style.maxWidth = (vContainerRects.width - this.sibling.style.maxWidth.replace("px", "")) + "px";
                    this.element.style.minWidth = (vContainerRects.width - this.sibling.style.maxWidth.replace("px", "")) + "px";
                    this.sibling.style.width = "100%"
                    this.sibling.style.maxWidth = "";
                    this.sibling.style.minWidth = "";
                    this.sibling.style.marginLeft = 0;
                }
            } else if (this.elementIsMin || this.siblingIsMin) {         
                this.#addWidthAnimationClasses();
                this.element.style.width = this.sizer.style.maxWidth;
                this.sibling.style.width = (100 - (+this.sizer.style.maxWidth.replace("%", ""))) + "%";
                if (this.direction === 'right') {
                    this.sibling.style.marginLeft = "";
                }
            }
            this.#setIcon();            
            this.element.style.marginLeft = "";
        } else {
            if (this.elementIsFixed) {
                if (this.direction === "left") {
                    const sbClientWidth = this.sibling.clientWidth;
                    const elClientWidth = this.element.clientWidth;
                    this.sibling.style.width = sbClientWidth + "px";
                    this.sibling.style.maxWidth = sbClientWidth + "px";
                    this.sibling.style.minWidth = sbClientWidth + "px";

                    this.element.style.width = "100%";
                    this.element.style.maxWidth = "";
                    this.element.style.minWidth = "";
                    this.sizer.style.maxWidth = elClientWidth + "px";
                    this.sibling.style.marginRight = sbClientWidth * -1 + "px";
                } else {
                    const elClientWidth = this.element.clientWidth;
                    this.sibling.style.width = "100%";
                    this.sibling.style.maxWidth = "";
                    this.sibling.style.minWidth = "";
                    this.sizer.style.maxWidth = elClientWidth + "px";
                    this.element.style.marginRight = elClientWidth * -1 + "px";
                }

            } else if (this.elementIsFixed) {
                const elClientWidth = this.element.clientWidth;
                const sbClientWidth = this.sibling.clientWidth;
                this.sizer.style.maxWidth = elClientWidth + "px";
                this.sibling.style.marginRight = sbClientWidth * -1 + "px";
            } else if (this.elementIsMin || this.siblingIsMin) {      
                this.#addWidthAnimationClasses();                          
                this.sizer.style.maxWidth = this.sibling.style.width;                
                if (this.direction === 'left') {
                    const sbClientWidth = this.sibling.clientWidth;
                    this.sibling.style.marginRight = sbClientWidth * -1 + "px"
                    this.sibling.style.width = sbClientWidth + "px";
                    this.element.style.width = "100%";
                } else {
                    const elClientWidth = this.element.clientWidth;
                    this.element.style.marginRight = elClientWidth * -1 + "px"
                    this.element.style.width = elClientWidth + "px";
                    this.sibling.style.width = "100%";
                }
            }

            this.sizer.classList.add("collapsedRight");
            this.collapseState = 'collapsedRight';
            this.#setIcon();
        }
        this.#fireResizeAfterAnimation();
        this.#saveToLocalStorage();
    }


    top() {
        this.#addTransitionEvents();
        let vContainerRects = this.element.parentElement.getBoundingClientRect();
        if (this.sizer.classList.contains("collapsedTop")) {
            return;
        }
        if (this.sizer.classList.contains("collapsedBottom")) {
            this.sizer.classList.remove("collapsedBottom");
            this.collapseState = null;
            if (this.elementIsFixed) {
                if (this.direction === "top") {
                    this.sibling.style.height = ((vContainerRects.height - (+this.sizer.style.maxHeight.replace("px", "")))) + "px";
                    this.sibling.style.maxHeight = (vContainerRects.height - (+this.sizer.style.maxHeight.replace("px", ""))) + "px";
                    this.sibling.style.minHeight = (vContainerRects.height - (+this.sizer.style.maxHeight.replace("px", ""))) + "px";
                    this.sibling.style.marginBottom = (vContainerRects.height - (+this.sizer.style.maxHeight.replace("px", ""))) * -1 + "px";
                } else {
                    this.element.style.marginBottom = 0;
                }

            } else if (this.elementIsMin || this.siblingIsMin) {
                this.#addHeighthAnimationClasses();
                this.sibling.style.height = this.sizer.style.maxHeight;
                this.element.style.height = (100 - (+this.sizer.style.maxHeight.replace("%", ""))) + "%";
            }

            this.sibling.style.marginBottom = "";
            this.#setIcon();
        } else {
            if (this.siblingIsFixed) {
                const elClientHeight = this.element.clientHeight;
                this.element.style.height = elClientHeight + "px";
                this.element.style.maxHeight = elClientHeight + "px";
                this.element.style.maxHeight = elClientHeight + "px";
                this.sibling.style.height = "100%";
                this.sibling.style.maxHeight = "";
                this.sibling.style.minHeight = "";
                this.sizer.style.maxHeight = elClientHeight + "px";
                this.element.style.marginTop = elClientHeight * -1 + "px"
            } else if (this.elementIsFixed) {
                if (this.direction === "top") {
                    const elClientHeight = this.element.clientHeight;
                    this.element.style.marginTop = elClientHeight * -1 + "px"
                    this.sizer.style.maxHeight = elClientHeight + "px";
                } else {
                    const sbClientHeight = this.sibling.clientHeight;
                    const elClientHeight = this.element.clientHeight;
                    this.sibling.style.height = sbClientHeight + "px";
                    this.sibling.style.maxHeight = sbClientHeight + "px";
                    this.sibling.style.minHeight = sbClientHeight + "px";
                    this.sibling.style.marginTop = sbClientHeight * -1 + "px";
                    this.element.style.height = "100%";
                    this.element.style.minHeight = "";
                    this.element.style.maxHeight = "";
                    this.sizer.style.maxHeight = elClientHeight + "px";
                }

            } else if (this.elementIsMin || this.siblingIsMin) {
                this.#addHeighthAnimationClasses();
                this.sizer.style.maxHeight = this.element.style.height;
                if (this.direction === 'top') {
                    const elClientHeight = this.element.clientHeight;
                    this.element.style.marginTop = elClientHeight * -1 + "px"
                    this.element.style.height = elClientHeight + "px";
                    this.sibling.style.height = "100%";
                } else {
                    const sbClientHeight = this.sibling.clientHeight;
                    this.sibling.style.marginTop = sbClientHeight * -1 + "px"
                    this.sibling.style.height = sbClientHeight + "px";
                    this.element.style.height = "100%";
                }
            } else if (this.siblingIsMin) {

            }

            this.sizer.classList.add("collapsedTop");
            this.collapseState = 'collapsedTop';
            this.#setIcon();
        }
        this.#fireResizeAfterAnimation();
        this.#saveToLocalStorage();
    }

    bottom() {
        this.#addTransitionEvents();
        let vContainerRects = this.element.parentElement.getBoundingClientRect();
        if (this.sizer.classList.contains("collapsedBottom")) {
            return;
        }

        if (this.sizer.classList.contains("collapsedTop")) {
            this.sizer.classList.remove("collapsedTop");
            this.collapseState = null;
            if (this.siblingIsFixed) {
                this.element.style.height = ((vContainerRects.height - (+this.sizer.style.maxHeight.replace("px", "")))) + "px";
                this.element.style.maxHeight = (vContainerRects.height - (+this.sizer.style.maxHeight.replace("px", ""))) + "px";
                this.element.style.minHeight = (vContainerRects.height - (+this.sizer.style.maxHeight.replace("px", ""))) + "px";
                this.element.style.marginTop = (vContainerRects.height - (+this.sizer.style.maxHeight.replace("px", ""))) * -1 + "px";
            } else if (this.elementIsMin || this.siblingIsMin) {
                this.#addHeighthAnimationClasses();
                this.element.style.height = this.sizer.style.maxHeight;
                this.sibling.style.height = (100 - (+this.sizer.style.maxHeight.replace("%", ""))) + "%";
                if (this.direction === 'bottom') {
                    this.sibling.style.marginTop = "";
                }
            } else if (this.elementIsFixed) {
                if (this.direction === "bottom") {
                    this.element.style.height = (vContainerRects.height - this.sibling.style.maxHeight.replace("px", "")) + "px";
                    this.element.style.maxHeight = (vContainerRects.height - this.sibling.style.maxHeight.replace("px", "")) + "px";
                    this.element.style.minHeight = (vContainerRects.height - this.sibling.style.maxHeight.replace("px", "")) + "px";
                    this.sibling.style.height = "100%"
                    this.sibling.style.maxHeight = "";
                    this.sibling.style.minHeight = "";
                    this.sibling.style.marginTop = 0;
                }
            }
            this.element.style.marginTop = "";
            this.#setIcon();
        } else {
            if (this.elementIsFixed) {
                if (this.direction === "top") {
                    const sbClientHeight = this.sibling.clientHeight;
                    const elClientHeight = this.element.clientHeight;
                    this.sibling.style.height = sbClientHeight + "px";
                    this.sibling.style.maxHeight = sbClientHeight + "px";
                    this.sibling.style.minHeight = sbClientHeight + "px";

                    this.element.style.height = "100%";
                    this.element.style.maxHeight = "";
                    this.element.style.minHeight = "";
                    this.sizer.style.maxHeight = elClientHeight + "px";
                    this.sibling.style.marginBottom = sbClientHeight * -1 + "px";
                } else {
                    const elClientHeight = this.element.clientHeight;
                    this.sibling.style.height = "100%";
                    this.sibling.style.maxHeight = "";
                    this.sibling.style.minHeight = "";
                    this.sizer.style.maxHeight = elClientHeight + "px";
                    var rect = this.element.getBoundingClientRect();
                    var diff = rect.top + rect.height - window.innerHeight;
                    this.element.style.marginBottom = (elClientHeight - diff) * -1 + "px";
                }

            } else if (this.elementIsFixed) {
                this.sizer.style.maxHeight = this.element.clientHeight + "px";
                this.sibling.style.marginBottom = this.sibling.clientHeight * -1 + "px";
            } else if (this.elementIsMin || this.siblingIsMin) {
                this.#addHeighthAnimationClasses();
                this.sizer.style.maxHeight = this.sibling.style.height;
                if (this.direction === 'top') {
                    const sbClientHeight = this.sibling.clientHeight;
                    this.sibling.style.marginBottom = sbClientHeight * -1 + "px"
                    this.sibling.style.height = sbClientHeight + "px";
                    this.element.style.height = "100%";
                } else {
                    const elClientHeight = this.element.clientHeight;
                    this.element.style.marginBottom = elClientHeight * -1 + "px"
                    this.element.style.height = elClientHeight + "px";
                    this.sibling.style.height = "100%";
                }
            } else if (this.siblingIsMin) {

            }
            this.sizer.classList.add("collapsedBottom");
            this.collapseState = 'collapsedBottom';
            this.#setIcon();
        }

        this.#fireResizeAfterAnimation();
        this.#saveToLocalStorage();
    }

    #setIcon() {
        if (this.sizer.classList.contains('collapsedLeft')) {
            this.setIconsState('right');
        } else if (this.sizer.classList.contains('collapsedRight')) {
            this.setIconsState('left');
        } else if (this.sizer.classList.contains('collapsedTop')) {
            this.setIconsState('bottom');
        } else if (this.sizer.classList.contains('collapsedBottom')) {
            this.setIconsState('top');
        } else {
            if (this.expand && this.collapse) {
                this.setIconsState(this.horizontal ? 'vertical' : 'horizontal');
            } else {
                if (this.collapse) {
                    this.setIconsState(this.direction);
                } else {
                    if (!this.horizontal) {
                        if (this.direction === 'right') {
                            this.setIconsState('left');
                        } else {
                            this.setIconsState('right');
                        }
                    } else {
                        if (this.direction === 'top') {
                            this.setIconsState('bottom');
                        } else {
                            this.setIconsState('top');
                        }
                    }
                }
            }
        }
        return;


        if (this.expand === false && this.sizer.classList.contains("collapsedLeft") && this.collapse === true) {
            this.sizer.childNodes[0].style.display = "none";
            this.sizer.childNodes[1].style.display = "inline";
        } else if (this.expand === false && this.sizer.classList.contains("collapsedRight") && this.collapse === true) {
            this.sizer.childNodes[0].style.display = "inline";
            this.sizer.childNodes[1].style.display = "none";
        } else if (this.expand === false && this.direction === "left") {
            this.sizer.childNodes[0].style.display = "inline";
            this.sizer.childNodes[1].style.display = "none";
        }


        if (this.collapse === false && this.sizer.classList.contains("collapsedRight") && this.expand === true) {
            this.sizer.childNodes[1].style.display = "none";
            this.sizer.childNodes[0].style.display = "inline";
        } else if (this.collapse === false && this.sizer.classList.contains("collapsedRight") && this.expand === true) {
            this.sizer.childNodes[1].style.display = "inline";
            this.sizer.childNodes[0].style.display = "none";
        } else if (this.collapse === false && this.direction === "right") {
            this.sizer.childNodes[1].style.display = "inline";
            this.sizer.childNodes[0].style.display = "none";
        }


        if (this.expand === false && this.sizer.classList.contains("collapsedTop") && this.collapse === true) {
            this.sizer.childNodes[0].style.display = "none";
            this.sizer.childNodes[1].style.display = "inline";
        } else if (this.expand === false && this.sizer.classList.contains("collapsedTop") && this.collapse === true) {
            this.sizer.childNodes[0].style.display = "inline";
            this.sizer.childNodes[1].style.display = "none";
        } else if (this.expand === false && this.direction === "top") {
            this.sizer.childNodes[0].style.display = "inline";
            this.sizer.childNodes[1].style.display = "none";
        }


        if (this.collapse === false && this.sizer.classList.contains("collapsedBottom") && this.expand === true) {
            this.sizer.childNodes[1].style.display = "none";
            this.sizer.childNodes[0].style.display = "inline";
        } else if (this.collapse === false && this.sizer.classList.contains("collapsedBottom") && this.expand === true) {
            this.sizer.childNodes[1].style.display = "inline";
            this.sizer.childNodes[0].style.display = "none";//.classList.remove("bi-chevron-right");
        } else if (this.collapse === false && this.direction === "bottom") {
            this.sizer.childNodes[1].style.display = "inline";
            this.sizer.childNodes[0].style.display = "none";
        }
    }

    /** Clears disrupting w-100 and h-100 classes from the containers */
    clearContainerClasses() {
        if (this.horizontal) {
            if (this.sibling?.classList?.contains('h-100')) {
                this.sibling.classList.remove('h-100');
                this.sibling.style.flexGrow = 0;
            }
            if (this.element?.classList?.contains('h-100')) {
                this.element.classList.remove('h-100');
                this.element.style.flexGrow = 0;
            }
        } else {
            if (this.sibling?.classList?.contains('w-100')) {
                this.sibling.classList.remove('w-100');
                this.sibling.style.flexGrow = 0;
            }
            if (this.element?.classList?.contains('w-100')) {
                this.element.classList.remove('w-100');
                this.element.style.flexGrow = 0;
            }
        }
    }

    #getXFromEvent(event) {
        if (event.type === 'touchmove') {
            return event.touches[0].clientX;
        } else {
            return event.x;
        }
    }

    #getYFromEvent(event) {
        if (event.type === 'touchmove') {
            return event.touches[0].clientY;
        } else {
            return event.y;
        }
    }
}